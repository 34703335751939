import { Assignment } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import CustomTable, {
  TableAction,
  TableConfig,
  TableField,
} from '../../../components/Table/CustomTable';
import { appURI, googleGeocodeApiURI, GOOGLE_API_KEY } from '../../../config';
import { getToken } from '../../../localstorage/auth';
import { createGeometryFromGeocodeResult } from '../../../utils/geoutil';
import styles from './Orders.module.scss';

const Orders = () => {
  const navigate = useNavigate();
  const [eventID, setEventID] = useState(-1);
  const [filterVisible, setFilterVisible] = useState(false);
  const [city, setCity] = useState(undefined);
  const [zip, setZip] = useState(undefined);
  const [onlyReserved, setOnlyReserved] = useState(undefined);
  const [searching, setSearching] = useState(undefined);
  const [searchBody, setSearchBody] = useState(undefined);
  const [margins, setMargins] = useState();

  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/orders/listv2`,
        headers: endPointHeaders,
        body: searchBody,
      },
    },
    [
      new TableAction(
        'plan',
        <Assignment />,
        'Plan',
        {
          variant: 'contained',
          color: 'secondary',
        },
        true
      ),
    ],
    null,
    {
      order: 'asc',
      orderBy: 'route.firstPickupTime',
    },
    [
      new TableField('execDateUTC', 'execDateUTC', 'Datum', 0, 0, 'dd/MM EE'),
      new TableField('vehiclecategory', 'vehicleCategory.name', 'Voertuig'),
      new TableField(
        'preferredDrivers',
        'preferredDrivers',
        'Voorkeur',
        0,
        0,
        null,
        false,
        null,
        null,
        [{ color: '#91ffde', tooltip: 'Voorkeur chauffeur inplannen op order' }]
      ),
      new TableField(
        'reserveGorseleOne',
        'reserveGorseleOne',
        'R',
        0,
        0,
        null,
        false,
        null,
        null,
        [{ color: '#91aade', tooltip: 'Gereserveerd voor deze werkgever.' }]
      ),
      new TableField('route', 'shortRoute', 'Route', 0, 0, 0, 0, true),
      new TableField('estimatedPrice', 'employerEstimatedPrice', 'Prijs'),
    ],
    false,
    [
      {
        prop: 'customer._id',
        value: '571f59698fa9be68034233c3',
      },
      {
        prop: 'waitingGorseleOneApproval',
        value: true,
      },
      {
        prop: 'isBeingPlanned',
        value: true,
      },
      {
        prop: 'disableGorseleOne',
        value: true,
      },
      {
        prop: 'waitingGorseleOneApproval',
        value: true,
      },
    ]
  );

  const planOrder = (selectedIds, selectedOrders) => {
    if (
      !selectedIds ||
      !selectedIds.length ||
      !selectedOrders ||
      !selectedOrders.length
    )
      return;
    return navigate(`/orders/${selectedIds[0]}`, {
      state: { drivers: selectedOrders[0].driversForPlanning },
    });
  };

  const resetFilter = () => {
    setFilterVisible(false);
    setCity(undefined);
    setZip(undefined);
    if (searchBody) {
      setSearchBody(undefined);
      setEventID(Date.now());
    }
  };

  const filterOrders = async (e) => {
    e.preventDefault();

    let citySearchData;
    let citySearchGeometry;
    if (city?.length && zip?.length) {
      setSearching(true);
      const fullAddressNoSpaces = encodeURI(
        `${zip}+${city}`.split(' ').join('+')
      );
      const url = googleGeocodeApiURI;
      const res = await fetch(
        `${url}?${new URLSearchParams({
          address: fullAddressNoSpaces,
          key: GOOGLE_API_KEY,
        })}`
      );
      setSearching(false);
      citySearchData = await res.json();
      if (citySearchData?.results?.length) {
        citySearchGeometry = createGeometryFromGeocodeResult(
          citySearchData.results[0]
        );
        if (!citySearchGeometry) {
          return Swal.fire(
            'Probleem',
            'Deze woonplaats kan niet op Google Maps gevonden worden',
            'error'
          );
        }
      } else {
        return Swal.fire(
          'Probleem',
          'Deze woonplaats kan niet op Google Maps gevonden worden',
          'error'
        );
      }
    }

    setSearchBody({
      filter: { geometry: citySearchGeometry, onlyReserved },
    });
    setEventID(Date.now());
  };

  const handleAction = (action, selectedIds, selectedItems) => {
    switch (action.id) {
      case 'plan':
        planOrder(selectedIds, selectedItems);
        break;
      default:
        break;
    }
  };

  const checkMargins = (result) => {
    if (result.hasOwnProperty('minus50Margin')) {
      setMargins({
        minus50: result.minus50Margin,
        plus50: result.plus50Margin,
        plus100: result.plus100Margin,
        plus250: result.plus250Margin,
      });
    }
  };

  const getMarginColor = (margin) => {
    switch (margin) {
      case -1:
        return 'red';
      case 0:
        return 'orange';
      case 1:
        return 'green';
      default:
        return 'green';
    }
  };

  return (
    <div>
      {searching ? <ScaleLoader loading /> : null}
      {!searching && filterVisible ? (
        <div className={styles.filter}>
          <span>Filter resultaat</span>
          <form onSubmit={filterOrders}>
            <label>Zoek in een straal van 20km rond een woonplaats</label>
            <div>
              <input
                type="text"
                value={zip || ''}
                placeholder="Postcode"
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                value={city || ''}
                placeholder="Woonplaats"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  value={onlyReserved}
                  onChange={() => setOnlyReserved(!onlyReserved)}
                />
                <span style={{ marginLeft: 8 }}>Enkel gereserveerd</span>
              </label>
            </div>
            <div>
              <Button
                size="small"
                color="inherit"
                style={{ marginRight: 16 }}
                onClick={() => resetFilter()}
              >
                Annuleer
              </Button>
              {(zip?.length && city?.length) || onlyReserved ? (
                <Button size="small" variant="contained" type="submit">
                  Zoeken
                </Button>
              ) : null}
            </div>
          </form>
        </div>
      ) : null}
      {margins ? (
        <div className={styles.margins}>
          <div style={{ backgroundColor: getMarginColor(margins.minus50) }}>
            -50
          </div>
          <div style={{ backgroundColor: getMarginColor(margins.plus50) }}>
            +50
          </div>
          <div style={{ backgroundColor: getMarginColor(margins.plus100) }}>
            +100
          </div>
          <div style={{ backgroundColor: getMarginColor(margins.plus250) }}>
            +250
          </div>
        </div>
      ) : null}
      <CustomTable
        config={tableConfig}
        onAction={handleAction}
        handleEvent={eventID}
        showFilter
        onFilter={() =>
          filterVisible ? resetFilter() : setFilterVisible(true)
        }
        noSelection
        sorting={[
          { label: 'Datum en ophaaluur oplopend', sort: 'default' },
          { label: 'Datum toegevoegd aflopend', sort: 'created' },
        ]}
        sendResultRows={true}
        onRowsResult={checkMargins}
      />
    </div>
  );
};

export default Orders;
